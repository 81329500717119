.navbar {
  height: 52px;
  background-color: $primary;
  z-index: 9999;

  @media screen and (max-width: 769px) {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
  }

  &-show {
    position: inherit;
    display: block;
  }

  &_navitems {
    margin: 5px;
    display: inline-block;
    text-decoration: none;
    padding: 13px 25px;
    color: $white;
    &:hover {
      background-color: $white;
      color: $primary;
    }

    &-active {
      background-color: $white;
      color: $primary;
    }

    @media screen and (max-width: 769px) {
      display: block;
      text-align: center;
    }
  }
}

.footer {
  background-image: url("../../assets/images/footer.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 400px;

  @media screen and (max-width: 769px) {
    height: auto;
  }
  * {
    color: #fff !important;
  }

  &-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    position: relative;
    opacity: 1;
    display: flex;
    align-items: center;
  }
}

@import "./variables.scss";

@import "./components/Header/Navigation/navbar.scss";
@import "./components/Header/Brand/brand.scss";
@import "./components/ui/Slider/slider.scss";
@import "./components/Footer/footer.scss";
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  padding: 0;
  margin: 0;
  // user-select: none;
}

.slider {
  margin-top: 140px;
  position: relative;
  min-height: calc(100vh - 140px);
  max-height: 400px;
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 769px) {
    height: calc(100vh - 90px);
    margin-top: 90px;
  }

  &-slide {
    // transition: all 0.5s 0.3s ease-in;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;

    &-overlay {
      position: absolute;
      z-index: -9;
      inset: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.6418942577030813) 0%,
        rgba(0, 0, 0, 0.0984768907563025) 100%,
        rgba(0, 0, 0, 0.0984768907563025) 100%
      );
    }
    &-image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -10;
    }

    &-active {
      opacity: 1;
    }

    &-cta {
      display: inline-block;
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 15px 50px;
      text-decoration: none;
      color: #fff;
      border-radius: 3px;
      margin: 25px 0;
      vertical-align: middle;
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      position: relative;
      transition-property: color;
      transition-duration: 0.3s;
      overflow: hidden;
      &:hover {
        border-color: $primary;
      }
      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        background: $primary;
        transform: scale(0);
        transition: transform 0.3s ease-out;
      }

      &:hover:before,
      &:focus:before,
      &:active:before {
        transform: scale(1);
      }
    }
    &-next,
    &-previous {
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 40%;
      z-index: 20;
      height: 75px;
      color: rgba(255, 255, 255, 0.2);
      font-size: 50px;
      cursor: pointer;
    }
    &-next {
      right: 0;
    }
    &-previous {
      left: 0;
    }
  }
}

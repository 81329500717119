.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  &-brand {
    display: flex;
    align-items: center;
    & img {
      height: 50px;
      margin-right: 10px;
    }
    & h1 {
      font-size: 30px;
      color: $primary;
    }
  }

  &-contact {
    display: flex;
    div:first-child {
      margin-right: 18px;
    }
  }
  &-toggle-menu {
    display: none;

    svg {
      height: 30px;
      width: auto;
    }
  }
  @media screen and (max-width: 769px) {
    height: 51px;
    padding: 20px 30px;
    &-brand {
      font-size: 20px;
    }
    &-contact {
      display: none;
    }

    &-toggle-menu {
      display: block;
    }
  }
}
